import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/png/logo-two.png";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from "../../assets/styles/blog.module.css";

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);

const BlogDetails = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <Title variant="hero">ShareMaster</Title>
                <Box className="d-flex justify-content-center">
                  <Text mr={3}>Jan 17, 2024</Text>
                  <Text>Michael Tippett</Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-0 pt-4">
          <Container>
            <Row>
              <Col lg="12" className="mb-5">
                {/* <!-- Blog section --> */}
                <Post>
                  <div>
                    <img
                      src={titleImage}
                      alt="ShareMaster"
                      className={styles.titleImage}
                    />
                  </div>
                  <div>
                    <h1>
                      Streamlining Efficiency with{" "}
                      <a href="https://sharemaster.io">ShareMaster</a>:
                      Effortless File Version Rollbacks
                    </h1>

                    <p>
                      Are you in search of a straightforward method to perform
                      file version rollbacks in your SharePoint document
                      library? Look no further than{" "}
                      <a href="https://sharemaster.io">ShareMaster</a>, a robust
                      tool designed to simplify version control and make
                      resetting file versions a breeze.
                    </p>

                    <p>
                      With <a href="https://sharemaster.io">ShareMaster</a>, you
                      can easily reset file versions. This feature is readily
                      available in the free-tier version of{" "}
                      <a href="https://sharemaster.io">ShareMaster</a>, allowing
                      you to select multiple files from your SharePoint document
                      library and specify the version to which you want to reset
                      them. It's an incredibly user-friendly process that can
                      save you valuable time and effort.
                    </p>

                    <h2>Important Note:</h2>
                    <p>
                      While utilizing{" "}
                      <a href="https://sharemaster.io">ShareMaster</a>'s Version
                      Reset feature is a powerful way to reset file versions,
                      it's crucial to understand how it works. Essentially, this
                      feature captures a snapshot of the most recent version and
                      duplicates it as version 1, subsequently advancing the
                      version number. As a result, the entire version history is
                      wiped clean, leaving only the latest file version and its
                      associated metadata intact. The primary objective of this
                      function is to reset the version number itself.
                    </p>

                    <p>
                      If your needs require similar functionality while
                      preserving both file and metadata versions, we invite you
                      to reach out to us via our website's{" "}
                      <a href="https://sharemaster.io">"Contact Us"</a> page.
                      Depending on demand, we may explore the development of a
                      tailored solution to meet your specific requirements.
                    </p>

                    <h3>Steps to Reset Version Numbers in ShareMaster:</h3>
                    <ol>
                      <li>
                        Upon launching{" "}
                        <a href="https://sharemaster.io">ShareMaster</a>,
                        navigate to Explore Master (included in the free-tier of{" "}
                        <a href="https://sharemaster.io">ShareMaster</a>).
                      </li>
                      <li>
                        Select{" "}
                        <a href="https://sharemaster.io">"Add Connection"</a>{" "}
                        and sign in to your SharePoint site.
                      </li>
                      <li>
                        Click on <a href="https://sharemaster.io">"Files"</a> in
                        the left-hand navigation panel.
                      </li>
                      <li>Locate the document library hosting your files.</li>
                      <li>Handpick the file or files you wish to reset.</li>
                      <li>
                        Opt for{" "}
                        <a href="https://sharemaster.io">"Reset Version."</a>
                      </li>
                      <li>
                        Choose your desired version and confirm by clicking{" "}
                        <a href="https://sharemaster.io">"Reset."</a>
                      </li>
                      <li>
                        Witness the successful rollback of your file version
                        number.
                      </li>
                    </ol>

                    <p>
                      To see a step-by-step demonstration of the process, check
                      out the video available{" "}
                      <a href="https://www.youtube.com/watch?v=SMPJHI3yJWM&feature=youtu.be">
                        here
                      </a>
                      . This video showcases the seamless process of resetting
                      file versions with{" "}
                      <a href="https://sharemaster.io">ShareMaster</a>,
                      highlighting its immense value for anyone engaged in
                      managing SharePoint document libraries.{" "}
                      <a href="https://sharemaster.io">ShareMaster</a> is
                      thoughtfully designed to elevate the efficiency of your
                      document library management tasks.
                    </p>

                    <p>
                      Embrace the capabilities of{" "}
                      <a href="https://sharemaster.io">ShareMaster</a> today and
                      revolutionize your version control process. Simplify the
                      management of your document library –{" "}
                      <a href="https://sharemaster.io">ShareMaster</a> makes it
                      easier than ever!
                    </p>
                  </div>
                </Post>
                <Box className="d-flex" mt={4}>
                  <BadgePost>Development Product</BadgePost>
                  <BadgePost>SharePoint</BadgePost>
                  <BadgePost>Document Managmenet</BadgePost>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default BlogDetails;
